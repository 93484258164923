class SystemNachrichtService {
    /**
     * Zeigt eine kleine Systeminfo an den User, die nach 5 Sekunden automatisch verschwindet
     * @param {string} inhalt
     * @param {int} type -1 = Error, 0 = Warning, 1 = Success
     */
    zeigeKleineNachricht(inhalt, type = 0) {
        const template = document.querySelector('[kleine-system-nachricht-template]');
        const feed = document.getElementById('nachrichten-feed');
        const nachrichtDiv = template.content.cloneNode(true).children[0];
        nachrichtDiv.innerText = inhalt;
        switch (type) {
            case -1:
                nachrichtDiv.className = 'bg-red';
                break;
            case 1:
                nachrichtDiv.className = 'bg-green';
                break;
            default:
                nachrichtDiv.className = 'bg-yellow';
                break;
            }
        feed.appendChild(nachrichtDiv);
        // Einblenden der Nachricht mit Animation
        setTimeout(() => {
            nachrichtDiv.style.opacity = '1';
        }, 100);
        // Ausblenden der Nachricht nach 2-5 Sekunden
        const duration = type === 1 ? 2500 : 5000;
        setTimeout(() => {
            nachrichtDiv.style.opacity = '0';
            nachrichtDiv.ontransitionend = () => nachrichtDiv.remove();
        }, duration);
    }
}

const systemNachrichtService = new SystemNachrichtService();
export default systemNachrichtService;
