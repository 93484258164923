function feiertagFilter(feiertage, bundesland, feiertageBetrieb) {
	let feiertageBundesland;
	switch (bundesland) {
		case 'Baden-Württemberg':
			feiertageBundesland = feiertage.filter((ft) => ft.bw);
			break;
		case 'Bayern':
			feiertageBundesland = feiertage.filter((ft) => ft.by);
			// Augsburger Friedensfest aussortieren
			if (!feiertageBetrieb.FriedensfestAugsburg) {
				feiertageBundesland = feiertageBundesland.filter((ft) => ft.fname !== 'Augsburger Friedensfest');
			}
			// Mariä Himmelfahrt aussortieren
			if (!feiertageBetrieb.MariaHimmelfahrt) {
				feiertageBundesland = feiertageBundesland.filter((ft) => ft.fname !== 'Mariä Himmelfahrt');
			}
			break;
		case 'Berlin':
			feiertageBundesland = feiertage.filter((ft) => ft.be);
			break;
		case 'Brandenburg':
			feiertageBundesland = feiertage.filter((ft) => ft.bb);
			break;
		case 'Bremen':
			feiertageBundesland = feiertage.filter((ft) => ft.hb);
			break;
		case 'Hamburg':
			feiertageBundesland = feiertage.filter((ft) => ft.hh);
			break;
		case 'Hessen':
			feiertageBundesland = feiertage.filter((ft) => ft.he);
			break;
		case 'Meklenburg-Vorpommern':
			feiertageBundesland = feiertage.filter((ft) => ft.mv);
			break;
		case 'Niedersachsen':
			feiertageBundesland = feiertage.filter((ft) => ft.ni);
			break;
		case 'Nordrhein-Westfalen':
			feiertageBundesland = feiertage.filter((ft) => ft.nw);
			break;
		case 'Rheinland-Pfalz':
			feiertageBundesland = feiertage.filter((ft) => ft.rp);
			break;
		case 'Saarland':
			feiertageBundesland = feiertage.filter((ft) => ft.sl);
			break;
		case 'Sachsen':
			feiertageBundesland = feiertage.filter((ft) => ft.sn);
			// Fronleichnam aussortieren
			if (!feiertageBetrieb.Fronleichnam) {
				feiertageBundesland = feiertageBundesland.filter((ft) => ft.fname !== 'Fronleichnam');
			}
			break;
		case 'Sachsen-Anhalt':
			feiertageBundesland = feiertage.filter((ft) => ft.st);
			break;
		case 'Schleswig-Holstein':
			feiertageBundesland = feiertage.filter((ft) => ft.sh);
			break;
		case 'Thüringen':
			feiertageBundesland = feiertage.filter((ft) => ft.th);
			// Fronleichnam aussortieren
			if (!feiertageBetrieb.Fronleichnam) {
				feiertageBundesland = feiertageBundesland.filter((ft) => ft.fname !== 'Fronleichnam');
			}
			break;
		default:
			feiertageBundesland = feiertage;
			break;
		}
	return feiertageBundesland;
}

export { feiertagFilter };
