import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import { forceUpdateMA } from './ma_daten.js';
import stammdatenService from './services/stammdatenService.js';

import systemNachrichtService from './services/systemNachrichtService.js';
import { debounce, erstelleOptionGroup, fuelleSelectOptionen, setMenuActive } from './util.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import basedataService from './services/basedataService.js';

let pdfDoc = null;
let pageIsRendering;
let pageNumIsPending;
let ctx;
let canvas;
let pageNum;

// Check for pages rendering
const queueRenderPage = (num) => {
    if (pageIsRendering) {
      pageNumIsPending = num;
    } else {
      renderPage(num);
    }
};

// Show Prev Page
const showPrevPage = () => {
    if (pageNum <= 1) {
      return;
    }
    pageNum--;
    queueRenderPage(pageNum);
};

// Show Next Page
const showNextPage = () => {
    if (pageNum >= pdfDoc.numPages) {
      return;
    }
    pageNum++;
    queueRenderPage(pageNum);
};

const debounceUpdateEntwurf = debounce(async () => {
    await updateEntwurf();
}, 1000);

const debounceUpdateDateiName = debounce(async (args) => {
    await editDateiName(args[0]);
});

// Global Event Listeners
window.myHandlers = window.myHandlers || {};
window.myHandlers.showNextPage = showNextPage;
window.myHandlers.showPrevPage = showPrevPage;
window.myHandlers.generiereArbeitsvertrag = generiereArbeitsvertrag;
window.myHandlers.updateEntwurf = updateEntwurf;
window.myHandlers.debounceUpdateEntwurf = debounceUpdateEntwurf;
window.myHandlers.togglePostfachViewer = togglePostfachViewer;
window.myHandlers.uploadDatei = uploadDatei;
window.myHandlers.erstellePDF = erstellePDF;
window.myHandlers.confirmPDFErstellung = confirmPDFErstellung;
window.myHandlers.debounceUpdateDateiName = debounceUpdateDateiName;

// render the page
function renderPage(num) {
    pageIsRendering = true;
    pdfDoc.getPage(num)
        .then((page) => {
            // Calculate the current width of the viewer container
            const pdfViewer = document.getElementById('pdf-viewer');
            const viewerContainerWidth = pdfViewer.clientWidth;
            // Calculate the width of the PDF document
            let viewport = page.getViewport({ scale: 1 });
            const pdfWidth = viewport.width;
            // Calculate the scale factor to cover the remaining width
            const scale = viewerContainerWidth / pdfWidth;
            // Set scale
            viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderCtx = {
                canvasContext: ctx,
                viewport
            };
            page.render(renderCtx).promise.then(() => {
                pageIsRendering = false;
                if (pageNumIsPending !== null) {
                    renderPage(pageNumIsPending);
                    pageNumIsPending = null;
                }
            });
            // Output current page
            document.querySelector('#page-num').textContent = num;
        })
        .catch((err) => {
            // Display error
            systemNachrichtService.zeigeKleineNachricht(err.message, -1);
        });
}

/**
 * initialisiert die Postfach Seite des aktuellen Mitarbeiters
 */
async function ladeMitarbeiterPostfach() {
    // Wir können das Postfach nur öffnen falls ein MA ausgewählt wurde
    if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
        resetPostfachViewer();
        document.getElementById('postfach-filename-edit').value = '';
        await fuelleVorlagenSelect();
        canvas = document.getElementById('pdf-canvas');
        ctx = canvas.getContext('2d');
        pageIsRendering = false;
        pageNumIsPending = null;
        pageNum = 1;
        holeVerzeichnisliste();
    } else {
        systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
    }
}

/**
 * Lädt die PDF aus dem Backend und rendert diese
 * @param {string} pdfUrl
 */
async function ladePDF(pdfUrl) {
    togglePostfachViewer(3);
    // dynamic import statement
    const pdfjsLib = await import('pdfjs-dist/webpack.js');
    // get Document
    pdfjsLib.getDocument(pdfUrl).promise
        .then((doc) => {
            pdfDoc = doc;
            document.querySelector('#page-count').textContent = pdfDoc.numPages;
            document.querySelector('#pdf-download').href = pdfUrl;
            renderPage(pageNum);
        })
        .catch((error) => {
            console.error('PDF loading error:', error);
        });
}

function ladeImage(imgUrl) {
    togglePostfachViewer(2);
    document.getElementById('postfach-img').src = imgUrl;
}

async function ladeMarkdown(dokumentID) {
    togglePostfachViewer(1);
    try {
        const response = await fetch(`/neolohn/api/dokument/md/${dokumentID}`);
        if (response.ok) {
            const markdownDatei = await response.json();
            window.myServices.editorService.setMarkdown(markdownDatei.Inhalt);
            window.myServices.editorService.setCurrentFile(markdownDatei);
        }
    } catch (error) {
        console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Markdown fehlgeschlagen!', -1);
    }
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in dem Select an.
 */
async function fuelleVorlagenSelect() {
	try {
        const select = document.querySelector('#postfach-vorlagen-select');
        select.innerHTML = '';
		const response = await fetch('/neolohn/api/vorlage');
		if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const listeEigeneVorlagen = await response.json();
        // Option Gruppe Eigene Vorlagen
        if (listeEigeneVorlagen.length > 0) {
            listeEigeneVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('eigene Vorlagen', listeEigeneVorlagen, '_id', 'Name'));
        }
        const listeStandardVorlagen = await basedataService.holeVorlagenBasedataProvider();
        // Option Gruppe Mustervorlagen
        if (listeStandardVorlagen.length > 0) {
            listeStandardVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('Mustervorlagen', listeStandardVorlagen, '_id', 'Name'));
        }
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Vorlagen fehlgeschlagen!', -1);
	}
}

/**
 * Sendet eine PUT Request an den Server, um den Dateinamen zu ändern
 * @param {HTMLElement} thisElement Input Filename
 */
async function editDateiName(thisElement) {
    const aktuelleDatei = document.querySelector('.dokument-item-active');
    if (thisElement.value === '') {
        return;
    }
    if (!aktuelleDatei) {
        systemNachrichtService.zeigeKleineNachricht('Keine Datei ausgewählt!', 0);
        return;
    }
    try {
        const dokumentID = aktuelleDatei.id;
        const newName = thisElement.value;
        const body = {
            DokumentID: dokumentID,
            NeuerName: newName
        };
        const response = await fetch('/neolohn/api/dokument/name', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (response.ok) {
            const neuesDokument = await response.json();
            aktuelleDatei.remove();
            const neuesDokumentHTML = erstelleDokumentHTML(neuesDokument);
            document.getElementById('dokumente-liste').insertAdjacentElement('afterbegin', neuesDokumentHTML);
            setMenuActive(neuesDokumentHTML, 'dokument-item-active');
            // Falls wir beim Entwurf Editor den Namen ändern, muss dieser auch seine Current File mit dem neuen Namen updaten
            if (window.myServices.editorService.getCurrentFile()._id === neuesDokument._id) {
                window.myServices.editorService.setFileName(neuesDokument.Name);
            }
            systemNachrichtService.zeigeKleineNachricht('Speichern Erfolgreich!', 1);
        }
    } catch (error) {
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Umbennenen der Datei!', -1);
    }
}

/**
 * Erstellt den Arbeitsvertrag bzw. ein Dokument aus einer Vorlage des Mitarbeiters.
 */
async function generiereArbeitsvertrag() {
    await forceUpdateMA();
    const select = document.querySelector('#postfach-vorlagen-select');
    const vorlageID = select.value;
    const isMustervorlage = select.selectedOptions[0].parentElement.label === 'Mustervorlagen';
    const mitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
    if (select.value === '' || !vorlageID || !mitarbeiterID) {
        systemNachrichtService.zeigeKleineNachricht('Vorlagenauswahl fehlerhaft!', 0);
        return;
    }

    try {
        const verzeichnisID = stammdatenService.unternehmensobjekt.Verzeichnisse.find((vz) => vz.VerzeichnisName === 'Entwürfe')?._id;
        const body = {
            VorlageID: vorlageID,
            MitarbeiterID: mitarbeiterID,
            VerzeichnisID: verzeichnisID,
            IsMustervorlage: isMustervorlage
        };
        const response = await fetch('/neolohn/api/arbeitsvertrag', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
        if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const result = await response.json();
        // Zeige den Entwurf im Editor in der Oberfläche an
        window.myServices.editorService.setCurrentFile(result);
        window.myServices.editorService.setMarkdown(result.Inhalt);
        document.getElementById('postfach-filename-edit').value = result.Name;
        const entwurfVerzeichnis = stammdatenService.unternehmensobjekt.Verzeichnisse.find((vz) => vz.VerzeichnisName === 'Entwürfe')._id;
        await holeDocListe(entwurfVerzeichnis);
        const element = document.getElementById(result._id);
        setMenuActive(element, 'dokument-item-active');
        togglePostfachViewer(1);
        systemNachrichtService.zeigeKleineNachricht('Entwurfserstellung erfolgerich!', 1);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Fehler bei der Erstellung des Arbeitsvertrags!', -1);
    }
}

/**
 * Erstellt ein PDF aus dem aktuellen Entwurf im Editor
 */
async function erstellePDF() {
    if (_.isEmpty(window.myServices.editorService.getCurrentFile())) {
        systemNachrichtService.zeigeKleineNachricht('Kein Entwurf ausgewählt!');
        return;
    }
    const entwurfID = window.myServices.editorService.getCurrentFile()._id;
    const verzeichnisSelect = document.getElementById('pdf-erstellen-verzeichnis-select');
    fuelleSelectOptionen(verzeichnisSelect, stammdatenService.unternehmensobjekt.Verzeichnisse.filter((vz) => vz.VerzeichnisName !== 'Papierkorb'), '_id', 'VerzeichnisName');
    const result = await SystemDialogService.instance.displayAsync('pdf-erstellen-dialog');
	if (!result.success) {
		// User aktion abgebrochen
		return;
	}
    try {
        const response = await fetch('/neolohn/api/erstelle-pdf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                DokumentID: entwurfID,
                VerzeichnisID: result.data.VerzeichnisID
            })
        });
        if (response.ok) {
            systemNachrichtService.zeigeKleineNachricht('PDF Erfolgreich erstellt!', 1);
        }
    } catch (error) {
        systemNachrichtService.zeigeKleineNachricht('PDF Erstellen gescheitert!', -1);
    }
}

async function confirmPDFErstellung() {
    SystemDialogService.instance.confirm({
		VerzeichnisID: document.getElementById('pdf-erstellen-verzeichnis-select').value
	});
}

/**
 * Updated ein Vertragsentwurf
 */
 async function updateEntwurf() {
	try {
		const mdContent = window.myServices.editorService.getMarkdown();
        const oldObject = window.myServices.editorService.getCurrentFile();
		// Bei leeren Content brauchen wir nicht speichern...
		if (mdContent === '' || _.isEmpty(window.myServices.editorService.getCurrentFile())) {
			return;
		}
        oldObject.Inhalt = mdContent;
        const response = await fetch('/neolohn/api/vertragsentwurf', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify(oldObject),
        });
        if (!response.ok) {
            if (response.status === 413) {
                systemNachrichtService.zeigeKleineNachricht('Entwurfsdatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
                return;
            }
            systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
            return;
        }
        const result = await response.json();
        window.myServices.editorService.setCurrentFile(result);
        systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
	}
}

/**
 * Ladet die Dokumente innerhalb eines Verzeichnisses (oder alle) für einen Mitarbeiter
 * @param {string} verzeichnisID ObjectId
 */
async function holeDocListe(verzeichnisID = '') {
    try {
        let query = `?MitarbeiterID=${stammdatenService.aktuellerMitarbeiter._id}`;
        if (verzeichnisID !== '') {
            query += `&VerzeichnisID=${verzeichnisID}`;
        }
        const response = await fetch(`/neolohn/api/dokument/liste${query}`);
        if (response.ok) {
            const result = await response.json();
            const sortedDocs = result.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
			const liste = document.querySelector('#dokumente-liste');
			liste.innerHTML = '';
            sortedDocs.forEach((item) => {
                // Datei Metadata in das Frontend rendern...
                const newItem = erstelleDokumentHTML(item);
				liste.appendChild(newItem);
			});
            if (verzeichnisID !== '') {
                const verzeichnisHTML = document.getElementById(verzeichnisID).children[0];
                setMenuActive(verzeichnisHTML, 'verzeichnis-item-active');
            }
        }
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Download Links konnten nicht geladen werden!', -1);
    }
}

// Erstellt das HTML Element für eine Datei inklusive Event Listener
function erstelleDokumentHTML(item) {
    const template = document.querySelector('[dokument-item-template]');
    const newItem = template.content.cloneNode(true).children[0];
    const itemName = newItem.querySelector('.dokument-name');
    itemName.innerText = item.Name;
    itemName.title = item.Name;
    const itemInfo = newItem.querySelector('.dokument-info');
    itemInfo.querySelector('[aria-label="dokument-UserUpdate"]').innerText = item.UserUpdate || item.UserErstellt;
    const datum = item.DatumUpdate || item.DatumErstellt;
    itemInfo.querySelector('[aria-label="dokument-DatumUpdate"]').innerText = dayjs(datum).format('DD.MM.YY HH:mm');
    const checkbox = newItem.querySelector('.dokument-freigeben-checkbox');
    checkbox.checked = item.Freigeben;
    checkbox.addEventListener('input', async (event) => {
        await freigabeDatei(checkbox, event);
    });
    // Verhindert, dass die Datei geladen wird, wir wollen nur die freigabe Aktion durchführen
    checkbox.addEventListener('click', async (event) => event.stopPropagation());
    newItem.id = item._id;
    const docIcon = newItem.querySelector('.dokument-icon');
    // je nach Dateityp, laden wir ein Icon
    switch (item.MimeType) {
        case 'application/pdf':
            docIcon.innerHTML = '<i class="bi bi-file-pdf-fill"></i>';
            break;
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
            docIcon.innerHTML = '<i class="bi bi-file-image-fill"></i>';
            break;
        case 'application/vnd.oasis.opendocument.text':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'image/tiff':
            docIcon.innerHTML = '<i class="bi bi-file-earmark-arrow-down-fill"></i>';
            break;
        default:
            docIcon.innerHTML = '<i class="bi bi-file-text-fill"></i>';
            break;
    }
    
    newItem.addEventListener('click', async () => {
        if (window.myServices.editorService.getCurrentFile) {
            await updateEntwurf();
            window.myServices.editorService.reset();
        }
        document.getElementById('postfach-filename-edit').value = item.Name;
        setMenuActive(newItem, 'dokument-item-active');
        const docUrl = `/neolohn/api/dokument/${item._id}`; 
        // je nach Dateityp, müssen wir den entsprechenden viewer laden
        switch (item.MimeType) {
            case 'text/markdown':
                await ladeMarkdown(item._id);
                break;
            case 'application/pdf':
                ladePDF(docUrl);
                break;
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
                ladeImage(docUrl);
                break;
            case 'application/vnd.oasis.opendocument.text':
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'image/tiff':
                downloadLink(docUrl, item.Name);
                togglePostfachViewer();
                break;
            default:
                systemNachrichtService.zeigeKleineNachricht('ungültiges Datenformat!', -1);
                togglePostfachViewer();
                break;
        }
    });
    return newItem;
}

function downloadLink(docUrl, fileName) {
    systemNachrichtService.zeigeKleineNachricht('Download startet...', 1);
    const link = document.createElement('a');
    link.href = docUrl;
    link.download = fileName;
    link.click();
    link.remove();
}

/**
 * Zeigt die Liste an Verzeichnissen im Unternehmen an
 */
async function holeVerzeichnisliste() {
    const verzeichnisse = stammdatenService.unternehmensobjekt.Verzeichnisse.filter((vz) => vz.VerzeichnisName !== 'Papierkorb');
    const template = document.querySelector('[verzeichnis-item-template]');
    // Wir resetten die Dokument und Verzeichnislisten, um zu vermeiden, dass Daten eines anderen Mitarbeiters verbleiben.
    const docListe = document.querySelector('#dokumente-liste');
    docListe.innerHTML = '';
    // Wenn mir Mitarbeiter wechseln, bleiben wir im gleichen Verzeichnis.
    const offenesVerzeichnis = document.querySelector('.verzeichnis-item-active');
    const liste = document.querySelector('#verzeichnisse-liste');
    if (offenesVerzeichnis) {
        const offenesVerzeichnisID = offenesVerzeichnis.closest('form').id;
        await holeDocListe(offenesVerzeichnisID);
    } else {
        liste.innerHTML = '';
        verzeichnisse.forEach((item) => {
            const newItem = template.content.cloneNode(true).children[0];
            newItem.querySelector('.verzeichnis-name').innerText = item.VerzeichnisName;
            const checkbox = newItem.querySelector('.verzeichnis-freigeben-checkbox');
            checkbox.checked = item.Freigeben;
            checkbox.addEventListener('input', async (event) => freigabeVerzeichnis(checkbox, event));
            checkbox.addEventListener('click', (event) => event.stopPropagation());
            newItem.id = item._id;
            newItem.addEventListener('click', async () => {
                await holeDocListe(item._id);
            });
            addDragAndDropEvents(newItem.children[0]);
            liste.appendChild(newItem);
        });
        // Zusätzlich geben wir die option alle Dateien anzuzeigen
        const alleVerzeichnis = template.content.cloneNode(true).children[0];
        alleVerzeichnis.querySelector('.verzeichnis-name').innerText = 'Alle';
        alleVerzeichnis.querySelector('.verzeichnis-freigeben-checkbox').style.display = 'none';
        alleVerzeichnis.addEventListener('click', async () => {
            setMenuActive(alleVerzeichnis.children[0], 'verzeichnis-item-active');
            await holeDocListe('');
        });
        liste.appendChild(alleVerzeichnis);
        // Schließlich kommt noch das Papierkorb Verzeichnis
        const papierkorb = stammdatenService.unternehmensobjekt.Verzeichnisse.find((vz) => vz.VerzeichnisName === 'Papierkorb');
        const papierkorbHMTL = template.content.cloneNode(true).children[0];
        papierkorbHMTL.id = papierkorb._id;
        papierkorbHMTL.classList.add('papierkorb-verzeichnis');
        papierkorbHMTL.querySelector('.verzeichnis-name').innerText = papierkorb.VerzeichnisName;
        papierkorbHMTL.querySelector('.verzeichnis-freigeben-checkbox').style.display = 'none';
        papierkorbHMTL.addEventListener('click', async () => {
            setMenuActive(papierkorbHMTL.children[0], 'verzeichnis-item-active');
            await holeDocListe(papierkorb._id);
        });
        addDragAndDropEvents(papierkorbHMTL.children[0], false);
        liste.appendChild(papierkorbHMTL);
    }
}

/**
 * Resettet alle Viewer und blendet dessen Content aus der Oberfläche aus.
 */
function resetPostfachViewer() {
    togglePostfachViewer(0);
    const imageViewer = document.getElementById('image-viewer');
    const pdfViewer = document.getElementById('pdf-viewer');
    window.myServices.editorService.reset();
    imageViewer.querySelector('#postfach-img').src = '';
    const pdfCanvas = pdfViewer.querySelector('#pdf-canvas');
    pdfCanvas.getContext('2d').clearRect(0, 0, pdfCanvas.width, pdfCanvas.height);
}

/**
 * Togglt den Conten Viewer im Mitarbeiter Postfach
 * @param {int} toggleId 1: Markdown, 2: Image, 3: PDF, default: alle Viewer ausblenden
 */
function togglePostfachViewer(toggleId) {
    const mdEditor = document.getElementById('markdown-viewer');
    const imageViewer = document.getElementById('image-viewer');
    const pdfViewer = document.getElementById('pdf-viewer');
    switch (toggleId) {
        case 1:
            mdEditor.style.display = 'flex';
            imageViewer.style.display = 'none';
            pdfViewer.style.display = 'none';
            break;
        case 2:
            mdEditor.style.display = 'none';
            imageViewer.style.display = 'block';
            pdfViewer.style.display = 'none';
            break;
        case 3:
            mdEditor.style.display = 'none';
            pdfViewer.style.display = 'block';
            imageViewer.style.display = 'none';
            break;
        default:
            mdEditor.style.display = 'none';
            pdfViewer.style.display = 'none';
            imageViewer.style.display = 'none';
            break;
    }
}

/**
 * Lädt die aktuelle Datei per fetch und formData hoch
 * @param {HTMLElement} thisElement file input Element des Verzeichnisses
 */
async function uploadDatei(thisElement) {
    const inputForm = thisElement.closest('form');
    const verzeichnisID = inputForm.id;
    const file = new FormData(inputForm);
    const allowedDataFormats = [
        'application/pdf',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/vnd.oasis.opendocument.text',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/tiff',
    ];
    if (!allowedDataFormats.includes(file.get('file-upload').type)) {
        systemNachrichtService.zeigeKleineNachricht('Nur .png, .jpg, .pdf, .odt, .doc, docx, .tiff Dateien sind erlaubt!');
        return;
    }
    file.append('VerzeichnisID', verzeichnisID);
    file.append('MitarbeiterID', stammdatenService.aktuellerMitarbeiter._id);
    try {
        const response = await fetch('/neolohn/api/dokument', {
            method: 'POST',
            body: file
        });
        if (response.ok) {
            await holeDocListe(verzeichnisID);
            systemNachrichtService.zeigeKleineNachricht('Upload erfolgerich!', 1);
        }
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Upload fehlgeschlagen!', -1);
    }
}

/**
 * Sendet die Anfrage an des Server, die ausgewählte Datei freizugeben (oder die Freigabe zu widerrufen)
 * @param {HTMLElement} thisElement input[type=checkbox]
 */
async function freigabeDatei(thisElement, event) {
    event.stopPropagation();
    const dokumentID = thisElement.closest('.dokument-liste-item').id;
    try {
        const response = await fetch('/neolohn/api/dokument/freigeben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ DokumentID: dokumentID })
        });
        if (response.ok) {
            systemNachrichtService.zeigeKleineNachricht('Dokumentfreigabe aktualisiert!', 1);
        }
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Aktualisierung fehlgeschlagen!', -1);
    }
}

/**
 * Sendet die Anfrage an des Server, das ausgewählte Verzeichnis freizugeben (oder die Freigabe zu widerrufen).
 * - Gibt alle im Verzeichnis unterliegenden Dateien frei, bzw. nicht mehr frei
 * @param {HTMLElement} thisElement input[type=checkbox]
 */
async function freigabeVerzeichnis(thisElement, event) {
    event.stopPropagation();
    const verzeichnisID = thisElement.closest('form').id;
    try {
        const response = await fetch('/neolohn/api/verzeichnis/freigeben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VerzeichnisID: verzeichnisID,
                MitarbeiterID: stammdatenService.aktuellerMitarbeiter._id
            })
        });
        if (response.ok) {
            systemNachrichtService.zeigeKleineNachricht('Verzeichnisfreigabe aktualisiert!', 1);
            await holeDocListe(verzeichnisID);
        }
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Aktualisierung fehlgeschlagen!', -1);
    }
}

/**
 * Verschiebt eine HTML Datei in ein anders Verzeichnis
 * @param {string} verzeichnisNeu ID des neuen Verzeichnisses
 * @param {string} dokumentID ID der Datei
 */
async function verschiebeDatei(verzeichnisNeu, dokumentID) {
    try {
        const body = {
            DokumentID: dokumentID,
            VerzeichnisID: verzeichnisNeu
        };
        const response = await fetch('/neolohn/api/dokument/verschieben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        if (response.ok) {
            const result = await response.json();
            // Wir updaten den aktuellen Content des Editors, damit dieser später keine alten Daten speichert.
            if (window.myServices.editorService.getCurrentFile()._id === result._id) {
                window.myServices.editorService.setCurrentFile(result);
            }
            systemNachrichtService.zeigeKleineNachricht('Verschieben der Datei erfolgreich!', 1);
        }
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Verschieben der Datei!', -1);
    }
}

/**
 * Fügt die Drag and Drop Events dem Verzeichnis hinzu
 * - markiert das Verzeichnis, falls es per Drag überflogen wird
 * - unmarkiert es, falls es nicht mehr überflogen wird
 * - ladet die Datei hoch falls die Datei gedropped wird ins Verzeichnis
 * @param {HTMLElement} verzeichnisElement
 */
function addDragAndDropEvents(verzeichnisElement, allowUploads = true) {
    const start = ['dragover', 'dragenter'];
    const end = ['dragleave', 'dragend'];
    start.forEach((dragEvent) => {
        verzeichnisElement.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            verzeichnisElement.classList.add('drop-zone-dragover');
        });
    });
    end.forEach((dragEvent) => {
        verzeichnisElement.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            verzeichnisElement.classList.remove('drop-zone-dragover');
        });
    });

    verzeichnisElement.addEventListener('drop', async (e) => {
        e.preventDefault();
        verzeichnisElement.classList.remove('drop-zone-dragover');
        // Falls wir eine Datei als HTML Element verschieben, müssen wir die DokumentID und neue VerzeichnisID angeben
        if (e.dataTransfer.getData('docID') !== '') {
            const docID = e.dataTransfer.getData('docID');
            const aktuellesVerzeichnis = document.querySelector('.verzeichnis-item-active').closest('form').id;
            const neuesVerzeichnis = verzeichnisElement.closest('form').id;
            const papierkorb = document.querySelector('.papierkorb-verzeichnis').id;
            await verschiebeDatei(neuesVerzeichnis, docID);
            // Das Dokument wird nur ausgeblendet, falls wir uns nicht in Alle Verzeichnis befinden (außer beim Verschieben in den Papierkorb).
            if (((aktuellesVerzeichnis !== '') || (aktuellesVerzeichnis === '' && neuesVerzeichnis === papierkorb)) && aktuellesVerzeichnis !== neuesVerzeichnis) {
                document.getElementById(docID).remove();
            }
            return;
        }
        // Falls wir richtige Dateien hochladen...
        if (allowUploads) {
            const input = verzeichnisElement.querySelector('.input-file-upload');
            input.files = e.dataTransfer.files;
            await uploadDatei(verzeichnisElement);
        }
    });
}

export {
    ladeMitarbeiterPostfach
};
