import _ from 'underscore';
import systemNachrichtService from './systemNachrichtService.js';
import stammdatenService from './stammdatenService.js';

class SchichtService {
    // Speicher Request an den Server für eine Schicht
    async speichereSchicht(schichtObjekt) {
        try {
            // wenn wir eine neue Schicht erstellen, ist eine POST Request
            const istNeu = _.isEmpty(schichtObjekt._id);
            const response = await fetch('/neolohn/api/zeiten/schichten/', {
                method: istNeu ? 'POST' : 'PUT',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(schichtObjekt),
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message, 0);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            systemNachrichtService.zeigeKleineNachricht('Erfolgreich gespeichert!', 1);
            await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
            return result;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Speichern!', -1);
            return false;
        }
    }

    // Liest eine Schicht aus
    async leseSchicht(schichtID) {
        try {
            const response = await fetch(`/neolohn/api/zeiten/schichten/${schichtID}`);
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.error(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Lesen der Schicht!', -1);
            return false;
        }
    }

    // Lese Request für alle Schichten des ausgewählten Mitarbeiters eines Zeitraums
    async leseAlleSchichtenMitarbeiter(datumVon, datumBis, mitarbeiterID) {
        // standardmäßig geben wir den aktuellen Monat an
        if (!datumVon && !datumBis) {
            datumVon = dayjs(window.myVars.aktuellesMonatsDatum).startOf('month');
            datumBis = dayjs(window.myVars.aktuellesMonatsDatum).endOf('month');
        }
        try {
            const response = await fetch(`/neolohn/api/zeiten/mitarbeiter/${mitarbeiterID}?StartDatum=${datumVon.toISOString()}&EndDatum=${datumBis.toISOString()}`);
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            return result;
        } catch (error) {
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Schichten!', -1);
            console.error(error);
            return false;
        }
    }

    // Lösch Request für eine bestimmte Schicht
    async zeitLoeschen(schichtID) {
        try {
            const response = await fetch(`/neolohn/api/zeiten/schichten/${schichtID}`, {
                method: 'DELETE',
                mode: 'cors',
            });
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            systemNachrichtService.zeigeKleineNachricht('Erfolgreich gelöscht!', 1);
            // wir holen die Mitarbeiter daten neu, für aktuelle Statuswerte nach Löschen einer Schicht...
            await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen!', -1);
            return false;
        }
    }

    /**
     * Erstellt einen genehmigten Urlaubsantrag und relevante Urlaubsschichten.
     * @param {string} mitarbeiterID objectId
     * @param {Date} urlaubVon
     * @param {Date} urlaubBis
     * @returns true (success) oder false (error)
     */
    async erstelleUrlaubsschichten(mitarbeiterID, urlaubVon, urlaubBis) {
        try {
            document.body.style.cursor = 'wait';
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubVon: urlaubVon,
                UrlaubBis: urlaubBis
            };
            const response = await fetch('/neolohn/api/zeiten/mitarbeiter/urlaub', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                const message = await response.text();
                systemNachrichtService.zeigeKleineNachricht(message);
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const result = await response.json();
            stammdatenService.aktuellerMitarbeiter = result;
            systemNachrichtService.zeigeKleineNachricht('Urlaub Erfolgreich gespeichert!', 1);
            document.body.style.cursor = 'auto';
            // wir holen die Mitarbeiter daten neu, für aktuelle Statuswerte nach Urlaubseintragung.
            await stammdatenService.holeMitarbeiter(mitarbeiterID);
            return true;            
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubsanfrage!', -1);
            document.body.style.cursor = 'auto';
            return false;
        }
    }

    /**
     * Stellt eine DELETE Request an den Server für die Widerrufung der Urlaubsschichten aus dem Antrag
     * @param {ObjectIdString} mitarbeiterID
     * @param {ObjectIdString} urlaubID
     * @param {String} statusUpdate
     * @returns Urlaubsantrag
     */
    async entferneUrlaub(mitarbeiterID, urlaubID, statusUpdate) {
        try {
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubID: urlaubID,
                StatusUpdate: statusUpdate
            };
            const response = await fetch('/neolohn/api/mitarbeiter/urlaub', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const mitarbeiter = await response.json();
            stammdatenService.aktuellerMitarbeiter = mitarbeiter;
            systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag erfolgreich widerrufen!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubswiderrufung!', -1);
            return false;
        }
    }

    /**
     * Stellt eine POST Request an den Server für die Erstellung der Urlaubsschichten aus dem Antrag
     * @param {ObjectIdString} mitarbeiterID
     * @param {ObjectIdString} urlaubID
     * @param {String} statusUpdate
     */
    async erstelleUrlaubAusAntrag(mitarbeiterID, urlaubID, statusUpdate) {
        try {
            const body = {
                MitarbeiterID: mitarbeiterID,
                UrlaubID: urlaubID,
                StatusUpdate: statusUpdate
            };
            const response = await fetch('/neolohn/api/mitarbeiter/urlaub', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            const mitarbeiter = await response.json();
            stammdatenService.aktuellerMitarbeiter = mitarbeiter;
            systemNachrichtService.zeigeKleineNachricht('Urlaubsantrag erfolgreich genehmigt!', 1);
            return true;
        } catch (error) {
            console.log(error);
            systemNachrichtService.zeigeKleineNachricht('Fehler bei der Urlaubserstellung!', -1);
            return false;
        }
    }
}

const schichtService = new SchichtService();
export default schichtService;
