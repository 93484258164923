class BasedataService {
    constructor() {
        // base data provier daten:
        this.lohnartenAuswahl = [];
        this.unfallsversicherungen = [];
        this.bundeslaender = [];
        this.laender = [];
        this.pgs = [];
        this.konfession = [];
        this.krankenkassen = [];
        this.beschaeftigungsarten = [];
        this.befristungen = [];
        this.egfz = [];
        this.feiertage = [];
        this.vorlagen = [];
    }

    /**
     * Initiales Laden aller Basedata Listen beim Start von neolohn
     */
    async init() {
        this.lohnartenAuswahl = await this.holeLohnartenBasedataProvider();
        this.unfallsversicherungen = await this.holeUVBasedataProvider();
        this.bundeslaender = await this.holeBundeslaenderBasedataProvider();
        this.laender = await this.holeLaenderBasedataProvider();
        this.pgs = await this.holePGSBasedataProvider();
        this.konfession = await this.holeKonfessionBasedataProvider();
        this.krankenkassen = await this.holeKrankenkassenBasedataProvider();
        this.beschaeftigungsarten = await this.holeBeschaeftigungsartenBasedataProvider();
        this.befristungen = await this.holeBefristungsgrundBasedataProvider();
        this.egfz = await this.holeEgfzBasedataProvider();
        this.feiertage = await this.holeFeiertageBasedataProvider();
        this.vorlagen = await this.holeVorlagenBasedataProvider();
    }

    async holeBundeslaenderBasedataProvider() {
        if (this.bundeslaender.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/bundeslaender-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                this.bundeslaender = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.bundeslaender;
    }

    async holeLaenderBasedataProvider() {
        if (this.laender.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/laender-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                result.sort((a, b) => a.Staat.localeCompare(b.Staat));
                this.laender = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.laender;
    }

    async holeUVBasedataProvider() {
        if (this.unfallsversicherungen.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/uv-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                result.sort((a, b) => a.NAME.localeCompare(b.NAME));
                this.unfallsversicherungen = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.unfallsversicherungen;
    }

    async holeLohnartenBasedataProvider() {
        if (this.lohnartenAuswahl.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/lohnarten-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                this.lohnartenAuswahl = result;
                this.lohnartenAuswahl.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    }
        return this.lohnartenAuswahl;
    }

    async holePGSBasedataProvider() {
        if (this.pgs.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/personengruppenschluessel-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                this.pgs = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.pgs;
    }

    async holeKonfessionBasedataProvider() {
        if (this.konfession.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/konfession-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                this.konfession = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.konfession;
    }

    async holeKrankenkassenBasedataProvider() {
        if (this.krankenkassen.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/krankenkassen-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                result.sort((a, b) => a.Kurzbezeichnung.localeCompare(b.Kurzbezeichnung));
                this.krankenkassen = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.krankenkassen;
    }

    async holeBeschaeftigungsartenBasedataProvider() {
        if (this.beschaeftigungsarten.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/beschaeftigungsarten-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                result.sort((a, b) => a.BeschaeftigungsartKurz.localeCompare(b.BeschaeftigungsartKurz));
                this.beschaeftigungsarten = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.beschaeftigungsarten;
    }

    async holeBefristungsgrundBasedataProvider() {
        if (this.befristungen.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/befristung-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                result.sort((a, b) => a.Kurz.localeCompare(b.Kurz));
                this.befristungen = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.befristungen;
    }

    async holeEgfzBasedataProvider() {
        if (this.egfz.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/entgeltfortzahlung-basedataprovider');
                if (response.ok) {
                    const result = await response.json();
                    // Pausen brauchen wir erstmal nicht explizit anzeigen...
                    this.egfz = result.filter((egfz) => !egfz.Pause);
                    return this.egfz;
                }
            } catch (error) {
                console.log(error);
                return [];
            }
      }
        return this.egfz;
    }

    async holeFeiertageBasedataProvider() {
        if (this.feiertage.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/feiertage-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                // todo: eventuell zeitraum eingrenzen...
                this.feiertage = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.feiertage;
    }

    async holeVorlagenBasedataProvider() {
        if (this.vorlagen.length <= 0) {
            try {
            const response = await fetch('/neolohn/api/vorlagen-basedataprovider');
            if (response.ok) {
                const result = await response.json();
                // todo: eventuell zeitraum eingrenzen...
                this.vorlagen = result;
            }
        } catch (error) {
            console.log(error);
                return [];
        }
    }
        return this.vorlagen;
    }
}

const basedataService = new BasedataService();
export default basedataService;
