window.app = window.app || {};

class ApplicationLauncher {
    /**
     * Speichert die Referenz auf den Launcher und gibt diese zurück.
     * @returns Referenz auf Launcher
     */
    static get instance() {
        if (!window.app.launcher) {
            window.app.launcher = new ApplicationLauncher();
        }
        return window.app.launcher;
    }

    constructor() {
        this.eventListeners = [];
        this.isReady = false;
    }

    pushEventListener(fn) {
        if (this.isReady) {
            fn();
            return;
        }
        this.eventListeners.push(fn);
    }

    setReady() {
        this.isReady = true;
        window.document.body.removeAttribute('hidden');
        this.eventListeners.forEach((listener) => {
            listener();
        });
    }
}

export default ApplicationLauncher;
