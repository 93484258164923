function checkLastVisitedOnLogin() {
    const status = localStorage.getItem('app-status');
    const lastUrl = localStorage.getItem('lastVisited');
    // Falls wir neu gestartet bzw. eingeloggt haben, laden wir die zuletzt besuchte Seite, falls sie abweicht von der aktuellen
    if (status === 'false' && lastUrl !== window.location.href) {
        localStorage.setItem('app-status', 'true');
        window.location.replace(lastUrl);
        return;
    }
    localStorage.setItem('app-status', 'true');
}

function storeLastVistiedOnLogout() {
    localStorage.setItem('app-status', 'false');
    localStorage.setItem('lastVisited', window.location.href);
}

export {
    checkLastVisitedOnLogin,
    storeLastVistiedOnLogout
};
