import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import { getSelectedMitarbeiterIDs } from './util.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.getZettelHTML = getZettelHTML;
window.myHandlers.downloadBerichtePDF = downloadBerichtePDF;
window.myHandlers.prevPage = prevPage;
window.myHandlers.nextPage = nextPage;

// globale variablen für die Pagination durch Berichte
let currentIndex = 0;
let htmlArray = [];

async function getZettelHTML(zettelType) {
    // active item auswahl
    document.querySelectorAll('.report-item-active').forEach((elem) => elem.classList.remove('report-item-active'));
    document.getElementById(zettelType).classList.add('report-item-active');
    const aktuellerZeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
	// wir nehmen alle angehakten Mitarbeiter, ansonsten den aktuell ausgewählten Mitarbeiter.
    const listeHTML = document.querySelectorAll('.ma-select-item');    
    const markedMA = Array.from(listeHTML).filter((maHTML) => maHTML.querySelector('[aria-label="ma-control-check"]').checked);
	// sammle alle angehakten MitarbeiterIDs
	const maList = [];
	if (markedMA.length >= 1) {
		markedMA.forEach((ma) => {
			maList.push(ma.id);
		});
	} else if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		// wenn wir keine Mitarbeiter angehakt haben, schicken wir nur den aktuell ausgwählten.
		maList.push(stammdatenService.aktuellerMitarbeiter._id);
	} else {
		await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
		return;
	}
    const result = await stammdatenService.postBerichteMA(maList, aktuellerZeitraum, zettelType);
    if (result && result.length > 0) {
       showReportHTML(result);
    } 
}

function showReportHTML(reports) {
    htmlArray = reports;
    const pagination = document.getElementById('report-pagination');
    // aktuelle Seitenzahl
    currentIndex = 0;
    // Zeige den Lohnzettel im HTML Viewer in der Oberfläche an
    const reportarea = document.getElementById('reportarea');
    reportarea.innerHTML = reports[currentIndex];
    pagination.querySelector('#report-page-start').innerText = currentIndex + 1;
    pagination.querySelector('#report-page-end').innerText = reports.length;
    pagination.classList.remove('hidden');
}

function prevPage() {
    const reportarea = document.getElementById('reportarea');
    currentIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
    reportarea.innerHTML = htmlArray[currentIndex];
    document.querySelector('#report-page-start').innerText = currentIndex + 1;
}

function nextPage() {
    const reportarea = document.getElementById('reportarea');
    currentIndex = currentIndex + 1 >= htmlArray.length ? htmlArray.length - 1 : currentIndex + 1;
    reportarea.innerHTML = htmlArray[currentIndex];
    document.querySelector('#report-page-start').innerText = currentIndex + 1;
}

async function ladeBerichteMA() {
    const pagination = document.getElementById('report-pagination');
    pagination.classList.add('hidden');
    const reportarea = document.getElementById('reportarea');
    // event listener für pagination mit Pfeiltasten
    reportarea.addEventListener('keyup', (event) => {
        if (event.key === 'ArrowLeft') {
            prevPage();
        }
        if (event.key === 'ArrowRight') {
            nextPage();
        }
    });
    currentIndex = 0;
    htmlArray = [];
    const inputKW = document.getElementById('report-kw');
    inputKW.value = window.myVars.aktuellesMonatsDatum.isoWeek();
    inputKW.max = window.myVars.aktuellesMonatsDatum.endOf('month').isoWeek();
    inputKW.min = window.myVars.aktuellesMonatsDatum.startOf('month').isoWeek();

    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    reportarea.innerHTML = '';
    if (_.isEmpty(aktuellerMA)) {
        return;
    }
    const auswahl = document.querySelector('.report-item-active').id;
    switch (auswahl) {
        case 'lohnabrechnung':
            await getZettelHTML('lohnabrechnung');
            break;
        case 'stundenzettel':
            await getZettelHTML('stundenzettel');
            break;
        case 'monatsuebersicht':
            await getZettelHTML('monatsuebersicht');
            break;
        case 'wochenuebersicht':
            await getZettelHTML('wochenuebersicht');
            break;
        default:
            await getZettelHTML('lohnabrechnung');
            break;
    }
    
}

async function downloadBerichtePDF() {
    // zettelType auswahl
    const zettelType = document.querySelector('.report-item-active').id;
    const aktuellerZeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
	const maList = getSelectedMitarbeiterIDs();
    if (_.isEmpty(maList)) {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
        return;
    }
    await stammdatenService.downloadBerichteMA(maList, aktuellerZeitraum, zettelType);
}

export { ladeBerichteMA };
