import _ from 'underscore';

class ValidationService {
    constructor(url) {
        this.errors = [];
        this.isValid = false;
        this.url = url;
    }

    get htmlRoot() {
        return this.htmlFormularRoot;
    }

    async validate(data) {
        this.errors = [];
        const response = await fetch(this.url.replace('id', data._id), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();
        if (_.isEmpty(result)) {
            return [];
        }
        return result;
    }
}

export default ValidationService;
