class CookieService {
    /**
     * function that stores the name of the visitor in a cookie variable:
     * @param {string} cname Cookie name
     * @param {string} cvalue Cookie value
     * @param {number} exdays expiration days
     */
    setCookie(cname, cvalue, exdays = 1) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    /**
     * function that returns the value of a specified cookie:
     * @param {string} cname Cookie name
     * @returns cooke value string
     */
    getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    deleteCookie() {
        document.cookie = '';
    }
}

export default CookieService;
